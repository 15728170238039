
import Vue from 'vue';
import AppBar from '@/components/Booking/AppBar/index.vue';
import { AppointmentInfo, BusinessInfo } from '@/api/booking';
import ConfirmDialog from '@/components/Common/Dialog/ConfirmDialog.vue';
import AppointmentInfoCard from '@/components/Booking/Appointment/AppointmentInfoCard.vue';

interface State {
  overlay: boolean,
  business: BusinessInfo | null,
  appointment: AppointmentInfo | null,
}

export default Vue.extend({
  name: 'AppointmentInfo',
  components: {
    AppointmentInfoCard,
    ConfirmDialog,
    AppBar,
  },
  mounted() {
    this.$apiBooking.getBusinessInfo(this.businessId)
      .then(response => {
        this.business = response.data.businessInfo;
      })
      .catch(error => {
        console.log(error);
      });
    this.$apiBooking.getAppointmentInfo(this.businessId, this.appointmentId)
      .then(response => {
        this.appointment = response.data.appointment;
      })
      .catch(error => {
        console.log(error);
      });
  },
  data(): State {
    return {
      overlay: false,
      business: null,
      appointment: null,
    };
  },
  computed: {
    businessId() {
      return this.$route.params.businessId;
    },
    appointmentId() {
      return this.$route.params.appointmentId;
    },
  },
  methods: {
    onBookAnother() {
      this.$router.push({ name: 'booking.show', params: { businessId: this.businessId } });
    },
    onCancel() {
      this.overlay = true;
      this.$apiBooking.cancelAppointment(this.businessId, this.appointmentId)
        .then(response => {
          this.appointment = response.data.appointment;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.overlay = false;
        });
    },
  },
});
