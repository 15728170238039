
import Vue, { PropType } from 'vue';
import { Viewer } from '@toast-ui/vue-editor';
import { BusinessInfo } from '@/api/booking';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@toast-ui/editor/dist/toastui-editor-viewer.css';

export default Vue.extend({
  name: 'Introduction',
  components: { Viewer },
  props: {
    business: {
      type: Object as PropType<BusinessInfo>,
      required: true,
    },
  },
});
