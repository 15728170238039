
import Vue, { PropType } from 'vue';
import { AppointmentInfo } from '@/api/booking';

interface State {
  showDialog: boolean,
}

export default Vue.extend({
  name: 'AppointmentInfoCard',
  props: {
    appointment: {
      type: Object as PropType<AppointmentInfo>,
      required: true,
    },
  },
  data(): State {
    return {
      showDialog: false,
    };
  },
  methods: {
    onConfirm() {
      this.showDialog = false;
      this.$emit('confirmed');
    },
  },
});
