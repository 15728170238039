
import Vue, { VueConstructor } from 'vue';
import { minLength, required } from 'vuelidate/lib/validators';
import InputValidationMixin from '@/mixins/Form/InputValidationMixin';

interface State {
  input: string,
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof InputValidationMixin>>).extend({
  name: 'NameInput',
  mixins: [InputValidationMixin],
  props: {
    value: String,
  },
  data(): State {
    return {
      input: this.value,
    };
  },
  validations() {
    return {
      input: { required, minLength: minLength(3) },
    };
  },
  computed: {
    inputErrors(): Array<string> {
      const errors: Array<string> = [];
      if (!this.$v.input.$dirty) {
        return errors;
      }

      if (!this.$v.input.minLength) {
        errors.push('Name must be minimum 3 characters!');
      }

      if (!this.$v.input.required) {
        errors.push('Name is required!');
      }

      return errors;
    },
  },
  watch: {
    value(newValue) {
      this.input = newValue;
      this.$v.$reset();
    },
    input(newValue) {
      if (!newValue) {
        return;
      }

      this.$emit('input', newValue);
    },
  },
});
