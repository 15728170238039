
import Vue, { PropType } from 'vue';
import { AppointmentTypeConf } from '@/api/portal';

export default Vue.extend({
  name: 'AppointmentTypeListItem',
  props: {
    appointmentType: {
      type: Object as PropType<AppointmentTypeConf>,
      required: true,
    },
  },
});
