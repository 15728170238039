
import Vue, { PropType } from 'vue';
import { BusinessInfo } from '@/api/booking';

export default Vue.extend({
  name: 'AppBar',
  props: {
    business: Object as PropType<BusinessInfo>,
  },
});
