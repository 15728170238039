
import Vue from 'vue';
import { AppointmentTypeConf, BusinessConf } from '@/api/portal';
import AppointmentTypeListItem from '@/components/Portal/AppointmentType/AppointmentTypeListItem.vue';
import CreateAppointmentTypeModal from '@/components/Portal/AppointmentType/Create/CreateAppointmentTypeModal.vue';

interface State {
  loaded: boolean,
  business?: BusinessConf
  appointmentTypes: Array<AppointmentTypeConf>
}

export default Vue.extend({
  name: 'AppointmentTypesView',
  components: { CreateAppointmentTypeModal, AppointmentTypeListItem },
  created() {
    this.fetchData();
  },
  data(): State {
    return {
      loaded: false,
      business: undefined,
      appointmentTypes: [],
    };
  },
  methods: {
    fetchData() {
      this.loaded = false;

      this.$apiPortal.getTenantInfo()
        .then(response => {
          console.log(response);

          if (response.data.business) {
            this.business = response.data.business;
            this.$apiPortal.getAppointmentTypes(this.business.id)
              .then(appointmentTypesResponse => {
                console.log(appointmentTypesResponse);
                this.appointmentTypes = appointmentTypesResponse.data.appointmentTypes;
                this.loaded = true;
              });
          } else {
            console.log('no business created, forwarding to create view');
            this.$router.push('business.create');
          }
        });
    },
  },
});
