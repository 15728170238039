var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"app":""}},[(!_vm.drawer)?_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = true}}}):_vm._e(),_c('v-app-bar-title',[_vm._v(" Portal ")])],1),_c('v-navigation-drawer',{attrs:{"bottom":false,"app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list-item-group',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v(" TODO Business name ")]),_c('v-list-item-subtitle',[_vm._v(" Brontly ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"grey lighten-1"},on:{"click":function($event){_vm.drawer = false}}},[_vm._v("mdi-close")])],1)],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-calendar-multiple")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_c('router-link',{attrs:{"to":{name: 'portal.calendar.show'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('span',{on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Calendar")])]}}])})],1)],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-folder-cog-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_c('router-link',{attrs:{"to":{name: 'portal.appointment-types.index'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('span',{on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Appointments")])]}}])})],1)],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-office-building-cog-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_c('router-link',{attrs:{"to":{name: 'portal.business.show'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('span',{on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Business")])]}}])})],1)],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-cog-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_c('router-link',{attrs:{"to":{name: 'portal.account.show'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('span',{on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Account")])]}}])})],1)],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout-variant")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_c('router-link',{attrs:{"to":{name: 'auth.logout'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('span',{on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v("Logout")])]}}])})],1)],1)],1)],1)],1),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('router-view',{attrs:{"name":"view"}})],1)],1),_c('v-footer',{attrs:{"app":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }