
import Vue, { PropType, VueConstructor } from 'vue';
import { required } from 'vuelidate/lib/validators';
import { BusinessConf } from '@/api/portal';
import { AvailableTimesInfo } from '@/api/booking';
import FormatTimeMixin from '@/mixins/FormatTimeMixin';
import InputValidationMixin from '@/mixins/Form/InputValidationMixin';

interface State {
  input: number,
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormatTimeMixin> & InstanceType<typeof InputValidationMixin>>).extend({
  name: 'TimeInput',
  mixins: [FormatTimeMixin, InputValidationMixin],
  props: {
    business: {
      type: Object as PropType<BusinessConf>,
      required: true,
    },
    appointmentTypeId: String,
    availableTimesInfo: Object as PropType<AvailableTimesInfo>,
    value: Number,
  },
  data(): State {
    return {
      input: this.value,
    };
  },
  validations() {
    return {
      input: { required },
    };
  },
  computed: {
    availableTimesOptions() {
      const availableTimes = this.value ? this.availableTimesInfo?.times.concat(this.value) : this.availableTimesInfo?.times;
      return availableTimes?.map(time => ({
        text: this.formatTimeSlot(time),
        value: time,
      }));
    },
    inputErrors(): Array<string> {
      const errors: Array<string> = [];
      if (!this.$v.input.$dirty) {
        return errors;
      }

      if (!this.$v.input.required) {
        errors.push('Time is required!');
      }

      return errors;
    },
  },
  watch: {
    value(newValue: number) {
      this.input = newValue;
      this.$v.$reset();
    },
    input(newValue) {
      if (!newValue) {
        return;
      }

      this.$emit('input', newValue);
    },
  },
});
