
import Vue, { PropType } from 'vue';
import { Viewer } from '@toast-ui/vue-editor';
import { AppointmentType } from '@/api/booking';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@toast-ui/editor/dist/toastui-editor-viewer.css';

interface State {
  selectedAppointmentType: AppointmentType | null
}

export default Vue.extend({
  name: 'AppointmentTypes',
  components: { Viewer },
  props: {
    appointmentTypes: {
      type: Array as PropType<AppointmentType[]>,
      required: true,
    },
    value: Object as PropType<AppointmentType | null>,
  },
  data(): State {
    return {
      selectedAppointmentType: this.value,
    };
  },
  methods: {
    selectAppointmentType(appointmentType: AppointmentType): void {
      this.$emit('input', appointmentType);
    },
  },
  watch: {
    value(newValue: AppointmentType) {
      this.selectedAppointmentType = newValue;
    },
  },
});
