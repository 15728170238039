
import Vue from 'vue';

export default Vue.extend({
  name: 'BookingLayout',
  data() {
    return {
      drawer: false,
      mini: true,
    };
  },
});
