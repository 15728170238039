
import { AppointmentTypeConf, BusinessConf, UpdateAppointmentTypeRequest } from '@/api/portal';
import Vue, { PropType } from 'vue';
import NameInput from '@/components/Portal/AppointmentType/Create/NameInput.vue';
import NumberInput from '@/components/Portal/AppointmentType/Create/NumberInput.vue';
import MarkupEditor from '@/components/Common/Form/MarkupEditor.vue';

interface State {
  dialog: boolean,
  name: string | undefined,
  description: string | undefined,
  duration: number | undefined,
  bufferBefore: number | undefined,
  bufferAfter: number | undefined,
  nameInputValid: boolean,
  durationInputValid: boolean,
  bufferBeforeInputValid: boolean,
  bufferAfterInputValid: boolean,
}

export default Vue.extend({
  name: 'CreateAppointmentTypeModal',
  components: { MarkupEditor, NumberInput, NameInput },
  props: {
    appointmentType: Object as PropType<AppointmentTypeConf>,
    business: {
      type: Object as PropType<BusinessConf>,
      required: true,
    },
  },
  data(): State {
    return {
      dialog: false,
      name: undefined,
      description: undefined,
      duration: undefined,
      bufferBefore: undefined,
      bufferAfter: undefined,
      nameInputValid: false,
      durationInputValid: false,
      bufferBeforeInputValid: false,
      bufferAfterInputValid: false,
    };
  },
  computed: {
    valid(): boolean {
      return this.nameInputValid && this.durationInputValid && this.bufferBeforeInputValid && this.bufferAfterInputValid;
    },
  },
  methods: {
    onConfirm(): void {
      console.log('confirm');

      const appointmentType: AppointmentTypeConf = {
        id: this.appointmentType?.id,
        name: this.name ?? '',
        description: this.description ?? undefined,
        appointmentScheduling: {
          duration: this.duration ?? 0,
          bufferBefore: this.bufferBefore ?? 0,
          bufferAfter: this.bufferAfter ?? 0,
        },
        generalScheduling: {},
        startTimesPerDay: [],
      };

      if (!this.appointmentType) {
        this.$apiPortal.createAppointmentType(this.business.id, { appointmentType })
          .then(response => {
            console.debug(response);
            this.$emit('success');
            this.dialog = false;
          })
          .catch(error => {
            console.error(error);
          });
      } else {
        const request: UpdateAppointmentTypeRequest = {
          appointmentType,
        };
        this.$apiPortal.updateAppointmentType(this.business.id, appointmentType.id, request)
          .then(response => {
            console.debug(response);
            this.$emit('updated', response.data.appointmentType);
            this.dialog = false;
          })
          .catch(error => {
            console.error(error);
          });
      }
    },
    onCancel(): void {
      this.dialog = false;
    },
  },
  watch: {
    dialog() {
      this.name = this.appointmentType?.name;
      this.description = this.appointmentType?.description;
      this.duration = this.appointmentType?.appointmentScheduling?.duration;
      this.bufferBefore = this.appointmentType?.appointmentScheduling?.bufferBefore;
      this.bufferAfter = this.appointmentType?.appointmentScheduling?.bufferAfter;
      this.nameInputValid = !!this.appointmentType;
      this.durationInputValid = !!this.appointmentType;
      this.bufferBeforeInputValid = true; // Optional
      this.bufferAfterInputValid = true; // Optional
    },
  },
});
