
import Vue, { PropType } from 'vue';
import {
  BusinessConf,
  TimeInfo,
  WorkingHours,
  WorkingHoursDay,
  WorkingHoursSlot,
} from '@/api/portal';
import moment from 'moment';
import WorkingHoursSlotModal from '@/components/Portal/Business/PickTimeRangeModal.vue';

interface State {
  dialog: boolean,
  slots: Array<WorkingHoursSlot>,
}

export default Vue.extend({
  name: 'EditBusinessHoursModal',
  components: { WorkingHoursSlotModal },
  props: {
    business: {
      type: Object as PropType<BusinessConf>,
      required: true,
    },
    workingHoursDay: {
      type: Object as PropType<WorkingHoursDay>,
      required: true,
    },
  },
  data(): State {
    return {
      dialog: false,
      slots: JSON.parse(JSON.stringify(this.workingHoursDay.slots)), // TODO Use Lodash to deep clone
    };
  },
  methods: {
    onConfirm(): void {
      console.log('confirm');

      // TODO Use Lodash to deep clone
      const updatedWorkingHours: WorkingHours = JSON.parse(JSON.stringify(this.business.workingHours));
      const updatedWorkingHoursDay = updatedWorkingHours.days.find(workingHoursDay => workingHoursDay.day === this.workingHoursDay.day);

      if (updatedWorkingHoursDay) {
        updatedWorkingHoursDay.slots = this.slots;
      }

      this.$apiPortal.updateBusiness(this.business.id, {
        workingHours: updatedWorkingHours,
      })
        .then(response => {
          console.debug(response);
          this.$emit('updated', response.data.business);
          this.dialog = false;
        })
        .catch(error => {
          console.error(error);
        });
    },
    onCancel(): void {
      this.dialog = false;
    },
    onSlotCreate(newValue: WorkingHoursSlot): void {
      this.slots.push(newValue);
    },
    onSlotUpdate(slot: WorkingHoursSlot, newValue: WorkingHoursSlot): void {
      const foundSlot = this.slots.find(tempSlot => tempSlot.start.hour === slot.start.hour
        && tempSlot.start.minute === slot.start.minute
        && tempSlot.end.hour === slot.end.hour
        && tempSlot.end.minute === slot.end.minute);

      if (foundSlot) {
        foundSlot.start.hour = newValue.start.hour;
        foundSlot.start.minute = newValue.start.minute;
        foundSlot.end.hour = newValue.end.hour;
        foundSlot.end.minute = newValue.end.minute;
      }
    },
    onDelete(slot: WorkingHoursSlot): void {
      console.log('deleting slot', slot);
      const index = this.slots.findIndex(tempSlot => tempSlot.start.hour === slot.start.hour
        && tempSlot.start.minute === slot.start.minute
        && tempSlot.end.hour === slot.end.hour
        && tempSlot.end.minute === slot.end.minute);

      console.log('found index', index);
      if (index > -1) {
        this.slots.splice(index, 1);
      }
    },
    formatTimeInfo(timeInfo: TimeInfo): string {
      return moment().hours(timeInfo.hour).minutes(timeInfo.minute).format('HH:mm');
    },
  },
  watch: {
    dialog() {
      this.slots = JSON.parse(JSON.stringify(this.workingHoursDay.slots)); // TODO Use Lodash to deep clone
    },
  },
});
