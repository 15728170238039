
import Vue, { PropType, VueConstructor } from 'vue';
import { Appointment, BusinessConf } from '@/api/portal';
import { AppointmentUpdatedEvent } from '@/views/Portal/Calendar/CalendarView.vue';
import ConfirmDialog from '@/components/Common/Dialog/ConfirmDialog.vue';
import CreateAppointmentModal from '@/components/Portal/Appointment/Create/CreateAppointmentModal.vue';
import FormatTimeMixin from '@/mixins/FormatTimeMixin';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormatTimeMixin>>).extend({
  name: 'AppointmentListItem',
  mixins: [FormatTimeMixin],
  components: { CreateAppointmentModal, ConfirmDialog },
  props: {
    business: {
      type: Object as PropType<BusinessConf>,
      required: true,
    },
    appointment: {
      type: Object as PropType<Appointment>,
      required: true,
    },
  },
  methods: {
    onDelete() {
      this.$apiPortal.deleteAppointment(this.business.id, this.appointment.id)
        .then(() => {
          this.$emit('deleted', this.appointment.id);
        });
    },
    onUpdated(appointment: Appointment) {
      const event: AppointmentUpdatedEvent = {
        oldIdToRemove: this.appointment.id,
        appointment,
      };
      this.$emit('updated', event);
    },
  },
});
