
import Vue, { PropType, VueConstructor } from 'vue';
import { AvailableTimesInfo } from '@/api/booking';
import { Appointment, BusinessConf, CreateAppointmentRequest } from '@/api/portal';
import AppointmentTypeInput from '@/components/Portal/Appointment/Create/AppointmentTypeInput.vue';
import DateInput from '@/components/Portal/Appointment/Create/DateInput.vue';
import TimeInput from '@/components/Portal/Appointment/Create/TimeInput.vue';
import ContactNameInput from '@/components/Portal/Appointment/Create/ContactNameInput.vue';
import ContactEmailInput from '@/components/Portal/Appointment/Create/ContactEmailInput.vue';
import momentTZ from 'moment-timezone';
import FormatTimeMixin from '@/mixins/FormatTimeMixin';

interface State {
  dialog: boolean,
  availableTimesInfo: AvailableTimesInfo | null, // Passed from date to time input
  appointmentTypeInput: string | null,
  dateInput: string | null,
  timeInput: number | null,
  nameInput: string | null,
  emailInput: string | null,
  appointmentTypeInputValid: boolean,
  dateInputValid: boolean,
  timeInputValid: boolean,
  nameInputValid: boolean,
  emailInputValid: boolean,
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormatTimeMixin>>).extend({
  name: 'CreateAppointmentModal',
  mixins: [FormatTimeMixin],
  components: {
    ContactEmailInput, ContactNameInput, TimeInput, DateInput, AppointmentTypeInput,
  },
  props: {
    business: {
      type: Object as PropType<BusinessConf>,
      required: true,
    },
    appointment: Object as PropType<Appointment>,
  },
  data(): State {
    return {
      dialog: false,
      availableTimesInfo: null,
      appointmentTypeInput: null,
      dateInput: null,
      timeInput: null,
      nameInput: null,
      emailInput: null,
      appointmentTypeInputValid: false,
      dateInputValid: false,
      timeInputValid: false,
      nameInputValid: false,
      emailInputValid: false,
    };
  },
  computed: {
    valid(): boolean {
      return this.appointmentTypeInputValid && this.dateInputValid && this.timeInputValid && this.nameInputValid && this.emailInputValid;
    },
  },
  methods: {
    onConfirm(): void {
      console.log('confirm');

      const startTime: string = momentTZ.tz(this.dateInput, this.business.timezone)
        .startOf('day')
        .add(this.timeInput, 'minutes')
        .format();

      const request: CreateAppointmentRequest = {
        appointmentTypeId: this.appointmentTypeInput ?? '',
        startTime,
        contact: {
          name: this.nameInput ?? '',
          email: this.emailInput ?? '',
        },
      };

      if (this.appointment) {
        this.$apiPortal.updateAppointment(this.business.id, this.appointment.id, request)
          .then(response => {
            this.$emit('success', response.data.appointment);
            this.dialog = false;
          })
          .catch(error => {
            console.error(error);
          });
      } else {
        this.$apiPortal.createAppointment(this.business.id, request)
          .then(response => {
            this.$emit('success', response.data.appointment);
            this.dialog = false;
          })
          .catch(error => {
            console.error(error);
          });
      }
    },
    onCancel(): void {
      this.dialog = false;
    },
    onAvailableTimesInfo(availableTimesInfo: AvailableTimesInfo) {
      this.availableTimesInfo = availableTimesInfo;
    },
  },
  watch: {
    dialog() {
      this.availableTimesInfo = this.appointment ? this.availableTimesInfo : null;
      this.appointmentTypeInput = this.appointment?.appointmentType?.id ?? null;
      this.dateInput = this.appointment?.schedule ? momentTZ(this.appointment.schedule.startTime).format('YYYY-MM-DD') : null;
      this.timeInput = this.appointment?.schedule ? this.timeToMinutes(this.appointment.schedule.startTime, this.business.timezone) : null;
      this.nameInput = this.appointment?.contact.name;
      this.emailInput = this.appointment?.contact.email;
      this.appointmentTypeInputValid = !!this.appointment;
      this.dateInputValid = !!this.appointment;
      this.timeInputValid = !!this.appointment;
      this.nameInputValid = !!this.appointment;
      this.emailInputValid = !!this.appointment;
    },
  },
});
