
import Vue, { PropType, VueConstructor } from 'vue';
import FormatTimeMixin from '@/mixins/FormatTimeMixin';

interface State {
  selectedTime?: number
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormatTimeMixin>>).extend({
  name: 'TimePicker',
  mixins: [FormatTimeMixin],
  props: {
    availableTimes: {
      type: Array as PropType<number[]>,
      required: true,
    },
    value: Number,
  },
  data(): State {
    return {
      selectedTime: this.value,
    };
  },
  watch: {
    selectedTime(newValue: number) {
      this.$emit('input', newValue);
    },
    value(newValue: number) {
      this.selectedTime = newValue;
    },
  },
});
