
import Vue, { PropType } from 'vue';
import { required, minLength, email } from 'vuelidate/lib/validators';
import { AppointmentType, BusinessInfo, ContactInfo } from '@/api/booking';
import momentTZ from 'moment-timezone';

interface State {
  name: string | null,
  email: string | null,
}

const ContactInfoTab = Vue.extend({
  name: 'ContactInfo',
  props: {
    business: {
      type: Object as PropType<BusinessInfo>,
      required: true,
    },
    appointmentType: {
      type: Object as PropType<AppointmentType>,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    time: {
      type: Number,
      required: true,
    },
  },
  data(): State {
    return {
      email: null,
      name: null,
    };
  },
  validations() {
    return {
      email: { required, email },
      name: { required, minLength: minLength(3) },
    };
  },
  computed: {
    formattedTime(): string {
      return momentTZ.tz(this.date, this.business.timezone)
        .startOf('day')
        .add(this.time, 'minutes')
        .format('llll');
    },
    isValid(): boolean {
      return !this.$v.$invalid;
    },
    emailErrors(): Array<string> {
      const errors: Array<string> = [];

      if (!this.$v.email.$dirty) {
        return errors;
      }

      if (!this.$v.email.required) {
        errors.push('Email is required!');
      }

      if (!this.$v.email.email) {
        errors.push('Need a proper email');
      }

      return errors;
    },
    nameErrors(): Array<string> {
      const errors: Array<string> = [];
      if (!this.$v.name.$dirty) {
        return errors;
      }

      if (!this.$v.name.required) {
        errors.push('Name is required!');
      }

      if (!this.$v.name.minLength) {
        errors.push('Name must be minimum 3 characters!');
      }

      return errors;
    },
  },
  methods: {
    updateContactInfo() {
      const updatedInfo: ContactInfo = {
        email: this.email ?? '',
        name: this.name ?? '',
      };

      this.$emit('input', updatedInfo);
    },
  },
  watch: {
    email() {
      this.updateContactInfo();
    },
  },
});

export type ContactInfoTabType = InstanceType<typeof ContactInfoTab>;
export default ContactInfoTab;

