
import Vue, { PropType } from 'vue';
import {
  AppointmentType,
  BookAppointmentRequest,
  BusinessInfo,
  ContactInfo,
} from '@/api/booking';
import momentTZ from 'moment-timezone';

interface State {
  inProgress: boolean,
  confirmed: boolean,
}

export default Vue.extend({
  name: 'Confirmed',
  props: {
    business: {
      type: Object as PropType<BusinessInfo>,
      required: true,
    },
    appointmentType: {
      type: Object as PropType<AppointmentType>,
      required: true,
    },
    contactInfo: {
      type: Object as PropType<ContactInfo>,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    time: {
      type: Number,
      required: true,
    },
  },
  data(): State {
    return {
      inProgress: true,
      confirmed: false,
    };
  },
  created() {
    const startTime: string = momentTZ.tz(this.date, this.business.timezone)
      .startOf('day')
      .add(this.time, 'minutes')
      .format();

    const request: BookAppointmentRequest = {
      startTime,
      contactInfo: this.contactInfo,
    };

    this.$apiBooking.bookAppointment(
      this.business.id,
      this.appointmentType.id,
      request,
    )
      .then(response => {
        console.log('success', response);
        this.$router.push({
          name: 'booking.appointment.show',
          params: {
            businessId: this.business.id,
            appointmentId: response.data.appointment.id,
          },
        });
        this.confirmed = true;
      })
      .catch(error => {
        console.log('fail', error);
        this.confirmed = false;
      })
      .finally(() => {
        this.inProgress = false;
      });
  },
});
