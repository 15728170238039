
import Vue, { PropType } from 'vue';
import { TimeInfo, WorkingHoursSlot } from '@/api/portal';
import moment from 'moment';

interface State {
  dialog: boolean,
  start?: string,
  end?: string,
}

export default Vue.extend({
  name: 'WorkingHoursSlotModal',
  props: {
    workingHourSlot: Object as PropType<WorkingHoursSlot>,
  },
  data(): State {
    return {
      dialog: false,
      // TODO Make methods available here
      start: this.workingHourSlot ? moment()
        .hours(this.workingHourSlot?.start.hour)
        .minutes(this.workingHourSlot?.start.minute)
        .format('HH:mm') : undefined,
      end: this.workingHourSlot ? moment()
        .hours(this.workingHourSlot?.end.hour)
        .minutes(this.workingHourSlot?.end.minute)
        .format('HH:mm') : undefined,
    };
  },
  methods: {
    onConfirm(): void {
      console.log('confirm');

      const start = moment(this.start, 'HH:mm');
      const end = moment(this.end, 'HH:mm');

      const updatedSlot: WorkingHoursSlot = {
        start: {
          hour: start.hours(),
          minute: start.minutes(),
        },
        end: {
          hour: end.hours(),
          minute: end.minutes(),
        },
      };

      this.$emit('updated', updatedSlot);

      this.dialog = false;
    },
    onCancel(): void {
      this.dialog = false;
    },
    formatTimeInfo(timeInfo: TimeInfo): string {
      return moment().hours(timeInfo.hour).minutes(timeInfo.minute).format('HH:mm');
    },
  },
  watch: {
    dialog() {
      this.start = this.workingHourSlot ? this.formatTimeInfo(this.workingHourSlot.start) : undefined;
      this.end = this.workingHourSlot ? this.formatTimeInfo(this.workingHourSlot.end) : undefined;
    },
  },
});
