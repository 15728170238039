<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'AccountView',
});
</script>
<template>
  <div>AccountView</div>
</template>
