
import Vue, { PropType } from 'vue';
import { BusinessConf } from '@/api/portal';

interface State {
  dialog: boolean,
  enabled: boolean,
  body: string,
}

export default Vue.extend({
  name: 'EditAdditionalInformationModal',
  props: {
    business: {
      type: Object as PropType<BusinessConf>,
      required: true,
    },
  },
  data(): State {
    return {
      dialog: false,
      enabled: this.business.additionalInformation?.enabled ?? false,
      body: this.business.additionalInformation?.body ?? '',
    };
  },
  methods: {
    onConfirm(): void {
      console.log('confirm');

      this.$apiPortal.updateBusiness(this.business.id, {
        additionalInformation: {
          enabled: this.enabled,
          body: this.body,
        },
      })
        .then(response => {
          console.debug(response);
          this.$emit('updated', response.data.business);
          this.dialog = false;
        })
        .catch(error => {
          console.error(error);
        });
    },
    onCancel(): void {
      this.dialog = false;
    },
  },
  watch: {
    dialog() {
      this.enabled = this.business.additionalInformation?.enabled ?? false;
      this.body = this.business.additionalInformation?.body ?? '';
    },
  },
});
