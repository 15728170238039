
import Vue from 'vue';
import Introduction from '@/components/Booking/Introduction/index.vue';
import AppointmentTypes from '@/components/Booking/AppointmentTypes/index.vue';
import Scheduler from '@/components/Booking/Scheduler/index.vue';
import Confirmed from '@/components/Booking/Confirmed/index.vue';
import ContactInfoTab, { ContactInfoTabType } from '@/components/Booking/ContactInfo/index.vue';
import { AppointmentType, BusinessInfo, ContactInfo } from '@/api/booking';
import AppBar from '@/components/Booking/AppBar/index.vue';

interface State {
  business: BusinessInfo | null
  appointmentTypes: AppointmentType[] | null,
  currentStep: number
  confirmed: boolean
  selectedAppointmentType: AppointmentType | null
  selectedDate: string | null
  selectedTime: number | null
  contactInfo: ContactInfo | null
}

export default Vue.extend({
  name: 'App',
  components: {
    AppBar,
    ContactInfoTab,
    Confirmed,
    Scheduler,
    AppointmentTypes,
    Introduction,
  },
  mounted() {
    this.$apiBooking.getBusinessInfo('blue-bird')
      .then(response => {
        this.business = response.data.businessInfo;
      })
      .catch(error => {
        console.log(error);
      });
    this.$apiBooking.getAppointmentTypes('blue-bird')
      .then(response => {
        this.appointmentTypes = response.data.appointmentTypes;
      })
      .catch(error => {
        console.log(error);
      });
  },
  data(): State {
    return {
      business: null,
      appointmentTypes: null,
      currentStep: 1,
      confirmed: false,
      selectedAppointmentType: null,
      selectedDate: null,
      selectedTime: null,
      contactInfo: null,
    };
  },
  computed: {
    validContactInfo(): boolean {
      return !!this.contactInfo && !(this.$refs.contactInfoTab as ContactInfoTabType).$v.$invalid;
    },
  },
  methods: {
    prevStep() {
      this.currentStep = Math.max(1, this.currentStep - 1);
    },
    nextStep() {
      this.currentStep = Math.min(4, this.currentStep + 1);
    },
    onSelectDate(newValue: string) {
      this.selectedDate = newValue;
    },
    onSelectTime(newValue: number) {
      this.selectedTime = newValue;
    },
    bookAnother() {
      this.currentStep = 1;
      this.confirmed = false;
      this.selectedAppointmentType = null;
      this.selectedDate = null;
      this.selectedTime = null;
      this.contactInfo = null;
    },
  },
});
