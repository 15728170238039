import moment from 'moment';
import Vue from 'vue';
import momentTZ from 'moment-timezone';

export default Vue.extend({
  methods: {
    formatTimeSlot(minuteOfTheDay: number): string {
      // TODO Validate time zone and DST issues
      return moment().startOf('day').add(minuteOfTheDay, 'minutes').format('HH:mm');
    },
    formatTime(dateTime: string, timezone: string) {
      return momentTZ.tz(dateTime, timezone).format('LT');
    },
    formatDateTime(dateTime: string, timezone: string) {
      return momentTZ.tz(dateTime, timezone).format('llll');
    },
    timeToMinutes(time: string, timezone: string): number {
      return momentTZ.tz(time, timezone).diff(momentTZ.tz(time, timezone).startOf('day'), 'minutes');
    },
  },
});
