
import Vue from 'vue';
import { BusinessConf, TimeInfo } from '@/api/portal';
import moment from 'moment';
import EditNameModal from '@/components/Portal/Business/EditNameModal.vue';
import EditTimezoneModal from '@/components/Portal/Business/EditTimezoneModal.vue';
import EditAdditionalInformationModal from '@/components/Portal/Business/EditAdditionalInformationModal.vue';
import EditTermsAndConditionsModal from '@/components/Portal/Business/EditTermsAndConditionsModal.vue';
import EditBusinessHoursModal from '@/components/Portal/Business/EditBusinessHoursModal.vue';

interface State {
  business?: BusinessConf
}

export default Vue.extend({
  name: 'BusinessView',
  components: {
    EditBusinessHoursModal,
    EditTermsAndConditionsModal,
    EditAdditionalInformationModal,
    EditTimezoneModal,
    EditNameModal,
  },
  created() {
    this.$apiPortal.getTenantInfo()
      .then(response => {
        console.log(response);
        this.business = response.data.business;
      });
  },
  data(): State {
    return {
      business: undefined,
    };
  },
  methods: {
    onBusinessConfUpdate(newValue: BusinessConf) {
      // this.business = newValue;
      Vue.set(this, 'business', newValue);
    },
    formatTimeInfo(timeInfo: TimeInfo): string {
      return moment().hours(timeInfo.hour).minutes(timeInfo.minute).format('HH:mm');
    },
  },
});
