
import Vue, { PropType, VueConstructor } from 'vue';
import { BusinessConf } from '@/api/portal';
import { AvailableTimesInfo } from '@/api/booking';
import { required } from 'vuelidate/lib/validators';
import InputValidationMixin from '@/mixins/Form/InputValidationMixin';

interface State {
  loaded: boolean,
  menu: boolean,
  input: string | null,
  availableTimes: AvailableTimesInfo[],
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof InputValidationMixin>>).extend({
  name: 'DateInput',
  mixins: [InputValidationMixin],
  props: {
    business: {
      type: Object as PropType<BusinessConf>,
      required: true,
    },
    appointmentTypeId: String,
    value: String,
  },
  data(): State {
    return {
      loaded: false,
      menu: false,
      input: this.value,
      availableTimes: new Array<AvailableTimesInfo>(),
    };
  },
  validations() {
    return {
      input: { required },
    };
  },
  computed: {
    disabled() {
      return !this.appointmentTypeId;
    },
    inputErrors(): Array<string> {
      const errors: Array<string> = [];
      if (!this.$v.input.$dirty) {
        return errors;
      }

      if (!this.$v.input.required) {
        errors.push('Date is required!');
      }

      return errors;
    },
  },
  methods: {
    fetchData(startDate?: string, endDate?: string) {
      this.loaded = false;

      // TODO Create separate API for portal
      this.$apiBooking.getAvailableTimes(this.business.id, this.appointmentTypeId, startDate, endDate)
        .then(response => {
          this.availableTimes = response.data.availableTimes;
          if (this.value) { // Edit flow, the value is already provided upfront
            this.emitAvailableTimes(this.value);
          }
          this.loaded = true;
        });
    },
    onPickerDateChange(yearAndMonth: string) {
      // TODO Optimize so that the next and previous months are in memory all the time and are fetched on the background
      this.fetchData(`${yearAndMonth}-01`);
    },
    emitAvailableTimes(date: string): void {
      const availableTimesInfo = this.availableTimes.find(availableTime => availableTime.date === date);

      if (availableTimesInfo) {
        this.$emit('available-times', availableTimesInfo);
      } else {
        this.$emit('available-times', null);
      }
    },
    isDateAllowed(input: string): boolean {
      if (this.value === input) {
        return true; // Allow also input date value for edit flows
      }

      // TODO Optimize to use map instead
      const availableTimesInfo = this.availableTimes.find(availableTime => availableTime.date === input);
      return !!availableTimesInfo && availableTimesInfo.times.length > 0;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.fetchData(); // TODO Fetch correct month data
        }
        this.input = newValue;
        this.$v.$reset();
      },
    },
    input(newValue) {
      if (!newValue) {
        return;
      }

      this.emitAvailableTimes(newValue);

      this.$emit('input', newValue);
    },
    appointmentTypeId(newValue: string) {
      if (newValue) {
        this.input = null; // TODO Reuse input date if appointment type is switched
        this.fetchData();
      }
    },
    availableTimes(newValue: AvailableTimesInfo[]) {
      // Find first date that has available times
      const availableTimeInfo = newValue.find(findAvailableTime => findAvailableTime.times.length > 0);
      if (availableTimeInfo && this.input == null) {
        this.input = availableTimeInfo.date;
      }
    },
  },
});
