
import Vue, { VueConstructor } from 'vue';
import { integer, required } from 'vuelidate/lib/validators';
import InputValidationMixin from '@/mixins/Form/InputValidationMixin';
import { RuleDecl } from 'vue/types/options.d';

interface State {
  input: number,
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof InputValidationMixin>>).extend({
  name: 'NumberInput',
  mixins: [InputValidationMixin],
  props: {
    label: {
      type: String,
      required: true,
    },
    required: Boolean,
    icon: String,
    value: Number,
  },
  data(): State {
    return {
      input: this.value,
    };
  },
  validations() {
    let rules: RuleDecl = {
      input: { integer },
    };

    if (this.required) {
      rules = {
        input: { required, integer },
      };
    }

    return rules;
  },
  computed: {
    inputErrors(): Array<string> {
      const errors: Array<string> = [];
      if (!this.$v.input.$dirty) {
        return errors;
      }

      if (!this.$v.input.integer) {
        errors.push('Value must be an integer!');
      }

      if (this.required && !this.$v.input.required) {
        errors.push('Value is required!');
      }

      return errors;
    },
  },
  watch: {
    value(newValue) {
      this.input = newValue;
      this.$v.$reset();
    },
    input(newValue) {
      if (!newValue) {
        return;
      }

      this.$emit('input', newValue);
    },
  },
});
