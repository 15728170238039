
import Vue from 'vue';
import { AppointmentTypeConf, BusinessConf } from '@/api/portal';
import ConfirmDialog from '@/components/Common/Dialog/ConfirmDialog.vue';
import CreateAppointmentTypeModal from '@/components/Portal/AppointmentType/Create/CreateAppointmentTypeModal.vue';
import MarkupViewer from '@/components/Common/Markup/MarkupViewer.vue';

interface State {
  loaded: boolean
  business?: BusinessConf
  appointmentType?: AppointmentTypeConf
}

export default Vue.extend({
  name: 'AppointmentTypeView',
  components: {
    MarkupViewer,
    CreateAppointmentTypeModal,
    ConfirmDialog,
  },
  created() {
    this.$apiPortal.getTenantInfo()
      .then(response => {
        console.log(response);

        if (response.data.business) {
          this.business = response.data.business;
          this.$apiPortal.getAppointmentType(this.business.id, this.appointmentTypeId)
            .then(appointmentTypeResponse => {
              console.log(appointmentTypeResponse);
              this.appointmentType = appointmentTypeResponse.data.appointmentType;
              this.loaded = true;
            });
        } else {
          console.log('no business created, forwarding to create view');
          this.$router.push('business.create');
        }
      });
  },
  data(): State {
    return {
      loaded: false,
      business: undefined,
      appointmentType: undefined,
    };
  },
  computed: {
    appointmentTypeId(): string {
      return this.$route.params.appointmentTypeId;
    },
  },
  methods: {
    onAppointmentTypeConfUpdate(newValue: AppointmentTypeConf) {
      this.appointmentType = newValue;
    },
    onDelete() {
      if (this.business) {
        this.loaded = false;
        this.$apiPortal.deleteAppointmentType(this.business.id, this.appointmentTypeId)
          .then(() => {
            this.$router.push({ name: 'portal.appointment-types.index' });
          });
      }
    },
  },
});
