
import Vue from 'vue';
import { Viewer } from '@toast-ui/vue-editor';

export default Vue.extend({
  name: 'MarkupViewer',
  components: { Viewer },
  props: {
    value: String,
  },
  watch: {
    value(newValue) {
      const viewer: Viewer = this.$refs.viewer as Viewer;
      viewer.invoke('setMarkdown', newValue);
    },
  },
});
