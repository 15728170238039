
import Vue from 'vue';

interface State {
  showDialog: boolean,
}

export default Vue.extend({
  name: 'ConfirmDialog',
  props: {
    title: String,
    text: String,
  },
  data(): State {
    return {
      showDialog: false,
    };
  },
  methods: {
    onConfirm() {
      this.showDialog = false;
      this.$emit('confirmed');
    },
  },
});
