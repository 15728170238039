
import Vue from 'vue';
import { Appointment, BusinessConf } from '@/api/portal';
import CreateAppointmentModal from '@/components/Portal/Appointment/Create/CreateAppointmentModal.vue';
import AppointmentListDay from '@/components/Portal/Appointment/AppointmentListDay.vue';
import momentTZ from 'moment-timezone';

interface State {
  loaded: boolean,
  business?: BusinessConf
  appointments: Array<Appointment>
}

interface AppointmentsPerDay {
  date: string,
  appointments: Appointment[],
}

interface AppointmentsPerDayList {
  [key: string]: AppointmentsPerDay,
}

export interface AppointmentUpdatedEvent {
  oldIdToRemove: string,
  appointment: Appointment,
}

export default Vue.extend({
  name: 'CalendarView',
  components: { AppointmentListDay, CreateAppointmentModal },
  created() {
    this.$apiPortal.getTenantInfo()
      .then(response => {
        console.log(response);
        if (response.data.business) {
          this.business = response.data.business;
          this.fetchData();
        } else {
          console.log('no business created, forwarding to create view');
          this.$router.push('business.create');
        }
      });
  },
  data(): State {
    return {
      loaded: false,
      business: undefined,
      appointments: [],
    };
  },
  computed: {
    appointmentsPerDay() {
      const result: AppointmentsPerDayList = {};

      this.appointments.forEach(appointment => {
        const key: string = momentTZ.tz(appointment.schedule.startTime, this.business?.timezone ?? '').format('YYYY-MM-DD');
        if (result[key]) {
          result[key].appointments.push(appointment);
        } else {
          result[key] = {
            date: key,
            appointments: [appointment],
          };
        }
      });

      return result;
    },
  },
  methods: {
    fetchData() {
      // TODO Make inline replacements in case of local modifications (create or update)
      this.loaded = false;
      if (this.business) {
        this.$apiPortal.getAppointments(this.business.id)
          .then(appointmentTypesResponse => {
            console.log(appointmentTypesResponse);
            this.appointments = appointmentTypesResponse.data.appointments;
            this.loaded = true;
          });
      } else {
        console.log('Business not set up in memory');
      }
    },
    onAppointmentCreated(appointment: Appointment): void {
      this.appointments = this.appointments.concat(appointment).sort((a, b) => a.schedule.startTime.localeCompare(b.schedule.startTime));
    },
    onAppointmentUpdated(event: AppointmentUpdatedEvent) {
      const temp = this.appointments.filter(current => current.id !== event.oldIdToRemove);
      this.appointments = temp.concat(event.appointment).sort((a, b) => a.schedule.startTime.localeCompare(b.schedule.startTime));
    },
    onAppointmentCancelled(appointmentId: string) {
      this.appointments = this.appointments.filter(appointment => appointment.id !== appointmentId);
    },
  },
});
