
import Vue, { PropType, VueConstructor } from 'vue';
import { Appointment, BusinessConf } from '@/api/portal';
import FormatTimeMixin from '@/mixins/FormatTimeMixin';
import AppointmentListItem from '@/components/Portal/Appointment/AppointmentListItem.vue';
import moment from 'moment/moment';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormatTimeMixin>>).extend({
  name: 'AppointmentListDay',
  components: { AppointmentListItem },
  mixins: [FormatTimeMixin],
  props: {
    business: {
      type: Object as PropType<BusinessConf>,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    appointments: {
      type: Array as PropType<Appointment[]>,
      required: true,
    },
  },
  computed: {
    formattedDay() {
      return moment(this.date).calendar({
        sameDay: '[Today], LL',
        nextDay: '[Tomorrow], LL',
        nextWeek: 'dddd, LL',
        sameElse: 'dddd, LL',
      });
    },
  },
});
