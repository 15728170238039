
import Vue, { PropType } from 'vue';
import { BusinessConf } from '@/api/portal';
import momentTZ from 'moment-timezone';

interface State {
  dialog: boolean,
  timezone: string,
}

export default Vue.extend({
  name: 'EditTimezoneModal',
  props: {
    business: {
      type: Object as PropType<BusinessConf>,
      required: true,
    },
  },
  data(): State {
    return {
      dialog: false,
      timezone: this.business.timezone,
    };
  },
  computed: {
    options(): string[] {
      return momentTZ.tz.names();
    },
  },
  methods: {
    onConfirm(): void {
      console.log('confirm');

      this.$apiPortal.updateBusiness(this.business.id, {
        timezone: this.timezone,
      })
        .then(response => {
          console.debug(response);
          this.$emit('updated', response.data.business);
          this.dialog = false;
        })
        .catch(error => {
          console.error(error);
        });
    },
    onCancel(): void {
      this.dialog = false;
    },
  },
  watch: {
    dialog() {
      this.timezone = this.business.timezone;
    },
  },
});
