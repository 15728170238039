
import Vue from 'vue';
import { Editor } from '@toast-ui/vue-editor';

// eslint-disable-next-line import/no-extraneous-dependencies
import '@toast-ui/editor/dist/toastui-editor.css';

interface State {
  input: string | null,
}

export default Vue.extend({
  name: 'MarkupEditor',
  components: { Editor },
  props: {
    value: String,
  },
  data(): State {
    return {
      input: this.value,
    };
  },
  computed: {
    options() {
      return {
        minHeight: '200px',
        language: 'en-US',
        useCommandShortcut: true,
        usageStatistics: false,
        hideModeSwitch: true,
      };
    },
  },
  methods: {
    onEditorChange() {
      const editor: Editor = this.$refs.editor as Editor;
      this.input = editor.invoke('getMarkdown');
    },
  },
  watch: {
    value(newValue) {
      this.input = newValue;
    },
    input(newValue) {
      if (!newValue) {
        return;
      }

      this.$emit('input', newValue);
    },
  },
});
