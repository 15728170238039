
import Vue, { PropType } from 'vue';
import TimePicker from '@/components/Booking/Scheduler/TimePicker.vue';
import { AppointmentType, AvailableTimesInfo, BusinessInfo } from '@/api/booking';

interface State {
  loaded: boolean,
  availableTimes: AvailableTimesInfo[]
  localSelectedDate?: string
  localSelectedTime?: number
}

export default Vue.extend({
  name: 'Scheduler',
  components: { TimePicker },
  props: {
    business: {
      type: Object as PropType<BusinessInfo>,
      required: true,
    },
    appointmentType: {
      type: Object as PropType<AppointmentType>,
      required: true,
    },
    selectedDate: String,
    selectedTime: Number,
  },
  created() {
    this.fetchData();

    // Find first date that has available times
    const availableTimeInfo = this.availableTimes.find(findAvailableTime => findAvailableTime.times.length > 0);
    if (availableTimeInfo && this.localSelectedDate == null) {
      this.localSelectedDate = availableTimeInfo.date;
    }
  },
  data(): State {
    return {
      loaded: false,
      availableTimes: new Array<AvailableTimesInfo>(),
      localSelectedDate: this.selectedDate,
      localSelectedTime: this.selectedTime,
    };
  },
  computed: {
    selectedDateTimes(): number[] {
      if (this.selectedDate) {
        const availableTimesInfo = this.availableTimes.find(entry => entry.date === this.selectedDate);
        if (availableTimesInfo) {
          return availableTimesInfo.times;
        }
      }

      return new Array<number>();
    },
  },
  methods: {
    fetchData(startDate?: string, endDate?: string): void {
      this.loaded = false;
      this.$apiBooking.getAvailableTimes(this.business.id, this.appointmentType.id, startDate, endDate)
        .then(response => {
          this.availableTimes = response.data.availableTimes;
          this.loaded = true;
        });
    },
    onPickerDateChange(yearAndMonth: string) {
      // TODO Optimize so that the next and previous months are in memory all the time and are fetched on the background
      this.fetchData(`${yearAndMonth}-01`);
    },
    isDateAllowed(input: string): boolean {
      // TODO Optimize to use map instead
      const availableTimesInfo = this.availableTimes.find(availableTime => availableTime.date === input);
      return !!availableTimesInfo && availableTimesInfo.times.length > 0;
    },
  },
  watch: {
    appointmentType() {
      this.fetchData();
    },
    localSelectedDate(newValue) {
      this.$emit('selected-date', newValue);
    },
    localSelectedTime(newValue) {
      this.$emit('selected-time', newValue);
    },
    selectedDate(newValue: string) {
      this.localSelectedDate = newValue;
    },
    selectedTime(newValue: number) {
      this.localSelectedTime = newValue;
    },
  },
});
