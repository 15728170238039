
import Vue, { PropType } from 'vue';
import { BusinessConf } from '@/api/portal';

interface State {
  dialog: boolean,
  name: string,
}

export default Vue.extend({
  name: 'EditNameModal',
  props: {
    business: {
      type: Object as PropType<BusinessConf>,
      required: true,
    },
  },
  data(): State {
    return {
      dialog: false,
      name: this.business.name,
    };
  },
  methods: {
    onConfirm(): void {
      console.log('confirm');

      this.$apiPortal.updateBusiness(this.business.id, {
        name: this.name,
      })
        .then(response => {
          console.debug(response);
          this.$emit('updated', response.data.business);
          this.dialog = false;
        })
        .catch(error => {
          console.error(error);
        });
    },
    onCancel(): void {
      this.dialog = false;
    },
  },
  watch: {
    dialog() {
      this.name = this.business.name;
    },
  },
});
