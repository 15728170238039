
import Vue, { PropType, VueConstructor } from 'vue';
import { required } from 'vuelidate/lib/validators';
import { AppointmentTypeConf, BusinessConf } from '@/api/portal';
import InputValidationMixin from '@/mixins/Form/InputValidationMixin';

interface State {
  loaded: boolean,
  input: string | null,
  appointmentTypes: Array<AppointmentTypeConf>
}

interface VuetifyOption {
  text: string,
  value: string,
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof InputValidationMixin>>).extend({
  name: 'AppointmentTypeInput',
  mixins: [InputValidationMixin],
  props: {
    business: {
      type: Object as PropType<BusinessConf>,
      required: true,
    },
    value: String,
  },
  created() {
    this.$apiPortal.getAppointmentTypes(this.business.id)
      .then(appointmentTypesResponse => {
        this.appointmentTypes = appointmentTypesResponse.data.appointmentTypes;
        this.loaded = true;
      });
  },
  data(): State {
    return {
      loaded: false,
      input: this.value,
      appointmentTypes: new Array<AppointmentTypeConf>(),
    };
  },
  validations() {
    return {
      input: { required },
    };
  },
  computed: {
    appointmentTypeOptions(): VuetifyOption[] {
      return this.appointmentTypes.map(appointmentType => ({
        text: appointmentType.name,
        value: appointmentType.id,
      }));
    },
    inputErrors(): Array<string> {
      const errors: Array<string> = [];
      if (!this.$v.input.$dirty) {
        return errors;
      }

      if (!this.$v.input.required) {
        errors.push('Appointment type is required!');
      }

      return errors;
    },
  },
  watch: {
    value(newValue) {
      this.input = newValue;
      this.$v.$reset();
    },
    input(newValue: string): void {
      if (!newValue) {
        return;
      }

      this.$emit('input', newValue);
    },
  },
});
